import React, { useState, useRef, useEffect } from 'react';
import Header from '../Header';
import { Container, Row, Col } from 'react-bootstrap';
import BrandImage from '../../assets/images/brand/brand.png';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiChevronDown, BiChevronUp, BiLink } from 'react-icons/bi';
import { ImCross } from 'react-icons/im';
import gql from 'graphql-tag';
import Query from '../../components/Query';
import ResortQuery from '../ResortQuery';
const Navbar = () => {
  const GET_BRANCH = gql`
    query NewQuery {
      branches(first: 100) {
        nodes {
          title
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          branches {
            description
          }
        }
      }
    }
  `;

  const GET_RESORTS = gql`
    query NewQuery {
      resorts(first: 100) {
        nodes {
          title
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          resorts {
            description
          }
        }
      }
    }
  `;

  const GET_SWEETS = gql`
    query NewQuery {
      sweets(first: 100) {
        nodes {
          title
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          sweets {
            description
          }
        }
      }
    }
  `;

  const [hotels, setHotels] = useState(false);
  const [resort, setResort] = useState(false);
  const [sweet, setSweet] = useState(false);

  const [hotelImage, setHotelImage] = useState('');
  const [hotelParagraph, setHotelParagraph] = useState('');
  const [hotelLink, setHotelLink] = useState('');

  const [resortImage, setResortImage] = useState('');
  const [resortParagraph, setResortParagraph] = useState('');
  const [resortLink, setResortLink] = useState('');

  const [sweetsImage, setSweetsImage] = useState('');
  const [sweetsParagraph, setSweetsParagraph] = useState('');
  const [sweetsLink, setSweetsLink] = useState('');

  // Mobile Nav States
  const [smallnavbar, setSmallNavbar] = useState(false);
  const [smallShow, setSmallShow] = useState(false);
  const [smallShowHotels, setSmallShowHotels] = useState(false);
  const [smallShowResorts, setSmallShowResorts] = useState(false);
  const [smallShowSweets, setSmallShowSweets] = useState(false);
  const [show, setShow] = useState(false);
  const closing = () => {
    setSmallShow(false);
    setSmallNavbar(false);
  };
  const onClickOutsideListener = () => {
    if (hotels) {
      setHotels(false);
    }
    if (resort) {
      setResort(false);
    }
    if (sweet) {
      setSweet(false);
    }
    document.removeEventListener('click', onClickOutsideListener);
  };

  return (
    <div>
      <>
        <section className='navBarContainer largenavbar'>
          <Header />
          <Container>
            <nav className='navBar'>
              <figure className='navBar-brand'>
                <Link to='/'>
                  <img src={BrandImage} alt='' />
                </Link>
              </figure>
              <ul
                className='navBar-menu'
                onMouseLeave={() => {
                  document.addEventListener('click', onClickOutsideListener);
                }}
              >
                <li
                  className='navBar-menu__items'
                  onMouseEnter={() => {
                    setHotels(!hotels);
                    setResort(false);
                    setSweet(false);
                  }}
                >
                  Hotels
                </li>
                <li
                  className='navBar-menu__items'
                  onMouseEnter={() => {
                    setHotels(false);
                    setResort(!resort);
                    setSweet(false);
                  }}
                >
                  Resort
                </li>
                <li
                  className='navBar-menu__items'
                  onMouseEnter={() => {
                    setSweet(!sweet);
                    setHotels(false);
                    setResort(false);
                  }}
                >
                  Sweets
                </li>
                <Link to='/rooms'>
                  <li className='navBar-menu__items'>Rooms</li>
                </Link>
                <Link to='/gallery'>
                  <li className='navBar-menu__items'>Gallery</li>
                </Link>
                {/* <Link to="/offer">
                        <li className="navBar-menu__items">Offers</li>
                      </Link> */}
                <Link to='/contact'>
                  <li className='navBar-menu__items'>Contact</li>
                </Link>
              </ul>

              <>
                {
                  <Query query={GET_BRANCH} id={null}>
                    {({ data: { branches } }) => {
                      if (hotelImage === '') {
                        setHotelImage(
                          branches.nodes[0]?.featuredImage?.node?.sourceUrl
                        );
                        setHotelParagraph(
                          branches.nodes[0]?.branches?.description
                        );
                        setHotelLink(`/branch/${branches.nodes[0]?.slug}`);
                      }

                      return (
                        <>
                          {hotels && (
                            <div
                              className='resortstoggle'
                              onMouseLeave={() => {
                                setHotels(false);
                              }}
                            >
                              <Row>
                                <>
                                  <Col md={4}>
                                    <div className='resortstoggle__leftnav'>
                                      <ul>
                                        {branches?.nodes?.map((m) => {
                                          return (
                                            <Link
                                              to={hotelLink}
                                              onMouseEnter={() => {
                                                setHotelImage(
                                                  m?.featuredImage?.node
                                                    ?.sourceUrl
                                                );
                                                setHotelParagraph(
                                                  m?.branches?.description
                                                );
                                                setHotelLink(
                                                  `/branch/${m?.slug}`
                                                );
                                              }}
                                            >
                                              <li>{m?.title}</li>
                                            </Link>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <div className='resortstoggle__rightnav'>
                                      <img src={hotelImage} alt='' />
                                      <p
                                        className='navdesc'
                                        dangerouslySetInnerHTML={{
                                          __html: hotelParagraph,
                                        }}
                                      />
                                      <Link to={hotelLink}>
                                        <button
                                          className='bton bton--sm bton--primary m-0  mt-2'
                                          onClick={() => {
                                            setHotels(false);
                                            setResort(false);
                                            setSweet(false);
                                          }}
                                        >
                                          Discover More
                                        </button>
                                      </Link>{' '}
                                    </div>
                                  </Col>
                                </>
                              </Row>
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Query>
                }
              </>
              <>
                <ResortQuery query={GET_RESORTS} id={null}>
                  {({ data: { resorts } }) => {
                    if (resortImage === '') {
                      setResortImage(
                        resorts.nodes[0]?.featuredImage?.node?.sourceUrl
                      );
                      setResortParagraph(
                        resorts.nodes[0]?.resorts?.description
                      );
                      setResortLink(`/resort/${resorts.nodes[0]?.slug}`);
                    }

                    return (
                      <>
                        {resort && (
                          <div
                            className='resortstoggle'
                            onMouseLeave={() => {
                              setResort(false);
                            }}
                          >
                            <Row>
                              <>
                                <Col md={3}>
                                  <div className='resortstoggle__leftnav'>
                                    <ul>
                                      {resorts?.nodes?.map((m) => {
                                        return (
                                          <Link
                                            onMouseEnter={() => {
                                              setResortImage(
                                                m?.featuredImage?.node
                                                  ?.sourceUrl
                                              );
                                              setResortParagraph(
                                                m?.resorts?.description
                                              );
                                              setResortLink(
                                                `/resort/${m?.slug}`
                                              );
                                            }}
                                            to={resortLink}
                                          >
                                            <li>{m?.title}</li>
                                          </Link>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className='resortstoggle__rightnav'>
                                    <img src={resortImage} alt='' />
                                    <p
                                      className='navdesc'
                                      dangerouslySetInnerHTML={{
                                        __html: resortParagraph,
                                      }}
                                    />
                                    <Link to={resortLink}>
                                      <button
                                        className='bton bton--sm bton--ghost m-0  mt-2'
                                        onClick={() => {
                                          setHotels(false);
                                          setResort(false);
                                          setSweet(false);
                                        }}
                                      >
                                        Discover More
                                      </button>{' '}
                                    </Link>{' '}
                                  </div>
                                </Col>
                              </>
                            </Row>
                          </div>
                        )}
                      </>
                    );
                  }}
                </ResortQuery>
              </>
              <>
                {
                  <Query query={GET_SWEETS} id={null}>
                    {({ data: { sweets } }) => {
                      if (sweetsImage === '') {
                        setSweetsImage(
                          sweets.nodes[0]?.featuredImage?.node?.sourceUrl
                        );
                        setSweetsParagraph(
                          sweets.nodes[0]?.sweets?.description
                        );
                        setSweetsLink(`/sweet/${sweets.nodes[0]?.slug}`);
                      }

                      return (
                        <>
                          {sweet && (
                            <div
                              className='resortstoggle'
                              onMouseLeave={() => {
                                setSweet(false);
                              }}
                            >
                              <Row>
                                <>
                                  <Col md={3}>
                                    <div className='resortstoggle__leftnav'>
                                      <ul>
                                        {sweets?.nodes?.map((m) => {
                                          return (
                                            <Link
                                              onMouseEnter={() => {
                                                setSweetsImage(
                                                  m?.featuredImage?.node
                                                    ?.sourceUrl
                                                );
                                                setSweetsParagraph(
                                                  m?.sweets?.description
                                                );
                                                setSweetsLink(
                                                  `/sweet/${m?.slug}`
                                                );
                                              }}
                                              to={sweetsLink}
                                            >
                                              <li>{m?.title}</li>
                                            </Link>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col md={9}>
                                    <div className='resortstoggle__rightnav'>
                                      <img src={sweetsImage} alt='' />
                                      <p
                                        className='navdesc'
                                        dangerouslySetInnerHTML={{
                                          __html: sweetsParagraph,
                                        }}
                                      />
                                      <Link to={sweetsLink}>
                                        <button
                                          className='bton bton--sm bton--ghost m-0 mt-2'
                                          onClick={() => {
                                            setHotels(false);
                                            setResort(false);
                                            setSweet(false);
                                          }}
                                        >
                                          Discover More
                                        </button>
                                      </Link>{' '}
                                    </div>
                                  </Col>
                                </>
                              </Row>
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Query>
                }
              </>
            </nav>
          </Container>
        </section>

        <Container>
          <div className='smallnavbar'>
            <div className='smallnavbar__image'>
              <Link to='/'>
                <img src={BrandImage} alt='' />
              </Link>
            </div>
            <div>
              <GiHamburgerMenu
                className='smallnavbar__openicon'
                onClick={() => setSmallNavbar(!smallnavbar)}
              />

              {smallnavbar && (
                <div className='smallnavbar__display'>
                  <Link to='/'>
                    <img src={BrandImage} alt='' />
                  </Link>
                  <ImCross
                    onClick={() => setSmallNavbar(false)}
                    className='smallnavbar__display--crossicon'
                  />

                  <ul className='smallnavbar__display--mainul'>
                    <li>
                      <Link
                        to=''
                        onClick={() => {
                          setSmallShow(!smallShow);
                          setSmallShowHotels(true);
                        }}
                      >
                        Hotels
                      </Link>
                      {show ? (
                        <BiChevronUp
                          onClick={() => setSmallShowHotels(!smallShowHotels)}
                          style={{
                            fontSize: '24px',
                            marginLeft: '10px',
                            color: '#A67D03',
                          }}
                        />
                      ) : (
                        <BiChevronDown
                          onClick={() => setSmallShowHotels(!smallShowHotels)}
                          style={{
                            fontSize: '24px',
                            marginLeft: '10px',
                            color: '#A67D03',
                          }}
                        />
                      )}
                      {smallShowHotels && (
                        <ul className='smallnavbar__display--mainul-subul'>
                          <Query query={GET_BRANCH} id={null}>
                            {({ data: { branches } }) => {
                              return branches.nodes?.map((a) => {
                                return (
                                  <>
                                    <li>
                                      <Link
                                        to={`/branch/${a?.slug}`}
                                        onClick={closing}
                                      >
                                        {a?.title}
                                      </Link>
                                    </li>
                                  </>
                                );
                              });
                            }}
                          </Query>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link
                        to=''
                        onClick={() => {
                          setSmallShowResorts(!smallShowResorts);
                          setSmallShowSweets(false);
                          setSmallShowHotels(false);
                        }}
                      >
                        Resorts
                      </Link>
                      {smallShowResorts ? (
                        <BiChevronUp
                          onClick={() => setSmallShowResorts(!smallShowResorts)}
                          style={{
                            fontSize: '24px',
                            marginLeft: '10px',
                            color: '#A67D03',
                          }}
                        />
                      ) : (
                        <BiChevronDown
                          onClick={() => setSmallShowResorts(!smallShowResorts)}
                          style={{
                            fontSize: '24px',
                            marginLeft: '10px',
                            color: '#A67D03',
                          }}
                        />
                      )}
                      {smallShowResorts && (
                        <ul className='smallnavbar__display--mainul-subul'>
                          <ResortQuery query={GET_RESORTS} id={null}>
                            {({ data: { resorts } }) => {
                              return resorts.nodes?.map((a) => {
                                return (
                                  <>
                                    <li>
                                      <Link to={`/resort/${a?.slug}`}>
                                        {a?.title}
                                      </Link>
                                    </li>
                                  </>
                                );
                              });
                            }}
                          </ResortQuery>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link
                        to=''
                        onClick={() => setSmallShowSweets(!smallShowSweets)}
                      >
                        Sweets
                      </Link>
                      {setSmallShowSweets ? (
                        <BiChevronDown
                          onClick={() => setSmallShowSweets(!smallShowSweets)}
                          style={{
                            fontSize: '24px',
                            marginLeft: '10px',
                            color: '#A67D03',
                          }}
                        />
                      ) : (
                        <BiChevronUp
                          onClick={() => setSmallShowSweets(!smallShowSweets)}
                          style={{
                            fontSize: '24px',
                            marginLeft: '10px',
                            color: '#A67D03',
                          }}
                        />
                      )}
                      {smallShowSweets && (
                        <ul className='smallnavbar__display--mainul-subul'>
                          <Query query={GET_SWEETS} id={null}>
                            {({ data: { sweets } }) => {
                              return sweets.nodes?.map((a) => {
                                return (
                                  <>
                                    <li>
                                      <Link
                                        to={`/branch/${a?.slug}`}
                                        onClick={closing}
                                      >
                                        {a?.title}
                                      </Link>
                                    </li>
                                  </>
                                );
                              });
                            }}
                          </Query>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link to='/rooms' onClick={() => setSmallNavbar(false)}>
                        Rooms
                      </Link>
                    </li>
                    <li>
                      <Link to='/gallery' onClick={() => setSmallNavbar(false)}>
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to='/offer' onClick={() => setSmallNavbar(false)}>
                        Offers
                      </Link>
                    </li>
                    <li>
                      <Link to='/contact' onClick={() => setSmallNavbar(false)}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Container>
      </>
    </div>
  );
};

export default Navbar;
