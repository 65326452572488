import React, { useState } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../../components/Query';
function Slider() {
  const GET_BRANCH = gql`
    query NewQuery {
      branches {
        nodes {
          title
        }
      }
    }
  `;
  const GET_ROOMS = gql`
    query NewQuery {
      rooms {
        nodes {
          title
          slug
          rooms {
            name
            description
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  const GET_CAROUSEL = gql`
    query NewQuery {
      assets {
        nodes {
          title
          slug
          assets {
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;

  const page = 'slider';
  const section = 'booknow';
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [selectBranch, setSelectBranch] = useState('');
  const [selectRoom, setSelectRoom] = useState('');
  const navigate = useNavigate();
  const formHandler = (e) => {
    e.preventDefault();
    navigate('/reservationform', {
      state: { checkIn, checkOut, selectBranch, selectRoom },
    });
    // if(checkIn || checkOut || selectBranch || selectRoom){
    //   navigate("/reservationform");
    // }
    // else{
    //   navigate("/rooms");
    // }
  };
  return (
    <section className='carouselWrapper'>
      <Query query={GET_CAROUSEL} page={page}>
        {({ data: { assets } }) => {
          return (
            <>
              <Carousel>
                {assets?.nodes[1]?.assets.image.map((a) => {
                  return (
                    <Carousel.Item>
                      <img
                        className='carouselWrapper-image'
                        src={a.sourceUrl}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </>
          );
        }}
      </Query>

      <div className='reservationform'>
        <Form onSubmit={formHandler}>
          <div className='d-lg-flex  justify-content-between align-items-center'>
            <Query query={GET_BRANCH} page={section}>
              {({ data: { branches } }) => {
                return (
                  <>
                    <div>
                      <Form.Label>Check IN</Form.Label>

                      <Form.Control
                        type='date'
                        value={checkIn}
                        onChange={(e) => setCheckIn(e.target.value)}
                      />
                    </div>
                    <div>
                      <Form.Label>Check OUT</Form.Label>

                      <Form.Control
                        type='date'
                        value={checkOut}
                        onChange={(e) => setCheckOut(e.target.value)}
                      />
                    </div>
                    <div>
                      <Form.Label>Select Branch</Form.Label>

                      <Form.Select
                        aria-label='Default select example'
                        value={selectBranch}
                        onChange={(e) => setSelectBranch(e.target.value)}
                      >
                        <option> Choose Branch</option>
                        {branches.nodes?.map((a) => {
                          return <option value={a.title}>{a.title}</option>;
                        })}
                      </Form.Select>
                    </div>
                  </>
                );
              }}
            </Query>
            <Query query={GET_ROOMS} page={section}>
              {({ data: { rooms } }) => {
                return (
                  <>
                    <div>
                      <Form.Label>Select Room</Form.Label>

                      <Form.Select
                        aria-label='Default select example'
                        value={selectRoom}
                        onChange={(e) => setSelectRoom(e.target.value)}
                      >
                        <option>Choose Room</option>
                        {rooms.nodes?.map((a) => {
                          return <option value={a.title}>{a.title}</option>;
                        })}
                      </Form.Select>
                    </div>
                    <div>
                      <button
                        className='btonn bton--lg bton--primary mt-4'
                        type='Submit'
                      >
                        Book&nbsp;Now{' '}
                      </button>
                    </div>
                  </>
                );
              }}
            </Query>
          </div>
        </Form>
      </div>
    </section>
  );
}

export default Slider;
